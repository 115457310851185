import React, { Context, createContext } from 'react';

import useHealthProfile from '.';
import { HealthProfileContextType } from './types';

export const defaultHealthProfile: HealthProfileContextType = {
  hasStarted: false,
  hasCompletedHealthProfile: () => Promise.resolve(false),
};

export const HealthProfileContext: Context<HealthProfileContextType> =
  createContext<HealthProfileContextType>(defaultHealthProfile);

export const HealthProfileProvider: React.FC = ({ children }) => {
  const value = useHealthProfile();

  return (
    <HealthProfileContext.Provider value={value}>
      {children}
    </HealthProfileContext.Provider>
  );
};
