import { logError } from 'utils/helpers';

export class BaseErrorResponse implements Error {
  constructor(
    public message: string,
    public fatal: boolean,
    public name: string,
    public component: string = 'Membership Intake',
  ) {
    this.message = message;
    this.fatal = fatal;
    this.name = name;
    logError(message, { context: { fatal, name, component } });
  }
}

export class ContextInitializationError extends BaseErrorResponse {
  constructor(
    public message: string = 'Context failed to initialize',
    public fatal: boolean = false,
  ) {
    super(message, fatal, 'ContextInitializationError');
  }
}

export class CreateStoreOrderError extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'CreateStoreOrderError');
  }
}

export class StripeStoreOrderCompleteError extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'StripeStoreOrderCompleteError');
  }
}
export class PaypalStoreOrderCompleteError extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'PaypalStoreOrderCompleteError');
  }
}
export class StoreOrderFailureError extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'StoreOrderFailureError');
  }
}

export class StripePaymentIntentError extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'StripePaymentIntentError');
  }
}

export class FinalizeOrderError extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'FinalizeOrderFailure');
  }
}
