import { Col } from '@everlywell/leaves';
import Layout from 'components/Layout';
import React, { useEffect } from 'react';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { APP_ROOT } from 'utils/constants/urls';

import * as S from './MembershipsInactivePage.styles';

/**
 * [Add the description for what this component does here].
 */
function MembershipsInactivePage() {
  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.MEMBERSHIP_INTAKE.NOT_FOUND,
      },
    });
  }, []);
  return (
    <Layout>
      <S.Container>
        <Col>
          <S.Title>We’re sorry, this page is no longer active.</S.Title>
          <S.Content>
            The Everlywell Memberships program has been discontinued. If you
            have questions, please email our Customer Care Team at{' '}
            <a href="mailto:contact@everlywell.com">contact@everlywell.com</a>.
          </S.Content>
          <S.ButtonWrapper>
            <S.RedirectButton appearance="primary" hasArrow href={APP_ROOT}>
              Go to homepage
            </S.RedirectButton>
          </S.ButtonWrapper>
        </Col>
      </S.Container>
    </Layout>
  );
}

export default MembershipsInactivePage;
