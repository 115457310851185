import {
  Button,
  colors,
  H3,
  mediaQueries,
  size,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${size.xl7}px 0 274px;
  overflow: hidden;
`;

const Title = styled(H3)`
  color: ${colors.teal4};
  text-align: center;
  margin-bottom: ${size.md}px;
`;

const Content = styled.p`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.light};
  text-align: center;
  width: 100%;

  a {
    color: ${colors.teal4};
    text-decoration: none;
  }

  ${mediaQueries.forTabletVerticalUp} {
    width: 50%;
    margin: auto;
    font-size: 18px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 42px;
`;

const RedirectButton = styled(Button)`
  ${mediaQueries.forPhoneOnly} {
    width: 100%;
  }
`;

export { Container, Title, Content, ButtonWrapper, RedirectButton };
