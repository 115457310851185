import Layout from 'components/Layout';
import MembershipsInactivePage from 'components/MembershipsInactivePage';
import { SEO } from 'components/SEO';
import { navigate } from 'gatsby-link';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React, { useEffect } from 'react';
import { track } from 'utils/analytics';
import { MEMBERSHIP_INTAKE } from 'utils/constants/dataTest';
import { UserSettingsProvider } from 'utils/hooks/useAccountSettings/context';
import { CreditRedemptionModalProvider } from 'utils/hooks/useCreditRedemptionModal/context';
import { HealthProfileProvider } from 'utils/hooks/useHealthProfile/context';

import { ANALYTICS } from '../../utils/constants/analytics';
import useDidMount from '../../utils/hooks/useDidMount';
import { MembershipIntakeProvider } from '../../utils/hooks/useMembershipIntake/context';

const MembershipIntakePage = (): React.ReactElement | null => {
  const { useMembershipIntake } = useFlags();
  useEffect(() => {
    track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        label: ANALYTICS.LABELS.MEMBERSHIP_INTAKE.PAGE,
      },
    });
  }, []);
  useDidMount(() => {
    if (!useMembershipIntake) navigate('/control-membership');
  }, [useMembershipIntake]);
  return useMembershipIntake ? (
    <UserSettingsProvider>
      <CreditRedemptionModalProvider>
        <div data-testid={MEMBERSHIP_INTAKE.INDEX.PAGE}>
          <SEO title="Membership Intake" />
          <Layout>
            <HealthProfileProvider>
              <MembershipIntakeProvider>
                <MembershipsInactivePage />
              </MembershipIntakeProvider>
            </HealthProfileProvider>
          </Layout>
        </div>
      </CreditRedemptionModalProvider>
    </UserSettingsProvider>
  ) : null;
};

export default MembershipIntakePage;
