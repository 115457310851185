import { useState } from 'react';
import { logError } from 'utils/helpers';

import { ConsumerId } from '../useConsumer/types';
import getHealthProfileProgress from './StoreApi';
import { HealthProfileContextType } from './types';

export const consumerIDInitialValue = undefined;
export const consumerIDNull = null;

export const useHealthProfile = (): HealthProfileContextType => {
  const [hasStarted, setHasStarted] = useState(false);
  const hasCompletedHealthProfile = async (consumerId: ConsumerId) => {
    try {
      const { data }: any = await getHealthProfileProgress(consumerId);
      setHasStarted(data.questionnaire.has_started);
      return data.questionnaire.has_started;
    } catch (err) {
      logError((err as Error).message, {
        component: 'useHealthProfile',
        method: 'hasCompletedHealthProfile',
      });
    }
  };

  return {
    hasStarted,
    hasCompletedHealthProfile,
  };
};

export default useHealthProfile;
