import React, { useRef } from 'react';
import { trackComponentVisibility } from 'utils/analytics';

type Props = {
  label: string;
  children: React.ReactNode;
  slug?: string;
};

export const TrackVisibilityWrapper = ({
  label,
  children,
  slug,
}: Props): JSX.Element => {
  const ref = useRef(null);
  trackComponentVisibility(ref, {
    label,
    ...(slug && {
      slug,
    }),
  });

  return <div ref={ref}>{children}</div>;
};
export default TrackVisibilityWrapper;
