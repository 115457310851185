import {DependencyList, useEffect, useRef} from "react";

const useDidMount = (cb: Function, dependencies: DependencyList) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      cb()
    } else {
      didMount.current = true;
    }
  }, dependencies)
}
export default useDidMount;
