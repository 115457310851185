import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { getAuthToken } from 'utils/cartHelpers';
import { STORE_ROOT } from 'utils/constants/urls';

export const getHealthProfileProgress = async (consumerId: any) => {
  const authToken = await getAuthToken();

  const HealthProfileProgressURL = `${STORE_ROOT}/health-profile/api/v2/questionnaires/health-profile/progress`;

  const data = {
    consumerId,
  };
  const healthProfileRequestOptions: AxiosRequestConfig = {
    data,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    method: 'GET',
    url: HealthProfileProgressURL,
  };

  return await axios(healthProfileRequestOptions);
};

export default getHealthProfileProgress;
