import { BaseApiError } from './types';
import { BaseErrorResponse } from '../errors';

export type StripeProcessorError = BaseApiError;

export type StoreMembershipError = BaseApiError;

export class StripeProcessorErrorResponse extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'StripeProcessorErrorResponse');
  }
}

export class StoreMembershipErrorResponse extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'StoreMembershipErrorResponse');
  }
}

export class PaypalSubscriptionErrorResponse extends BaseErrorResponse {
  constructor(public message: string, public fatal: boolean = false) {
    super(message, fatal, 'PaypalSubscriptionErrorResponse');
  }
}

export function handleError<T extends BaseApiError>(
  error: T,
): BaseErrorResponse {
  switch (error.error_class) {
    case 'StripeProcessor::ApiExceptions::NotFoundError':
      return new StripeProcessorErrorResponse(
        'There was a problem handling the request, please try again',
        false,
      );
    case 'StripeProcessor::ApiExceptions::PriceNotFoundError':
      return new StripeProcessorErrorResponse(
        'Product not properly configured in Stripe',
        true,
      );
    default:
      return new StoreMembershipErrorResponse(error.message, false);
  }
}
